import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCircleXmark, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Flex from '../Flex';

import './Popup.scss';

const isFunction = func => typeof func === 'function';

function Popup(props, ref) {
	const [open, setOpen] = useState(!!props.open);
	const [isOpening, setIsOpening] = useState(false);
	const [isOpened, setIsOpened] = useState(false);
	const animationTime = 300; //ms

	const { onOpen, afterOpen, onClose, afterClose, title, loading, preventClose } = props;
	const onOpenCallback = useCallback(() => isFunction(onOpen) && onOpen(), [onOpen]);
	const afterOpenCallback = useCallback(() => isFunction(afterOpen) && afterOpen(), [afterOpen]);
	const onCloseCallback = useCallback(() => isFunction(onClose) && onClose(), [onClose]);
	const afterCloseCallback = useCallback(() => isFunction(afterClose) && afterClose(), [afterClose]);

	useEffect(() => {
		if (!!open) {
			onOpenCallback();

			setTimeout(() => {
				setIsOpening(true);

				setTimeout(() => {
					setIsOpened(true);
					afterOpenCallback();
				}, 0);
			}, 0);
		}
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		document.querySelector('body').classList.toggle('noscroll', !!open);

		return () => document.querySelector('body').classList.remove('noscroll');
	}, [open]);

	const setOpenProxy = useCallback((open) => {
		if (preventClose && !open) return;

		setOpen(open);

		if (!!open) onOpenCallback();
		else onCloseCallback();

		setTimeout(() => {
			setIsOpening(open);

			setTimeout(() => {
				setIsOpened(open);

				if (!!open) afterOpenCallback();
				else afterCloseCallback();
			}, animationTime);
		}, 0);
	}, [preventClose, onOpenCallback, afterOpenCallback, onCloseCallback, afterCloseCallback]);

	// Open on init if props.open === true
	useEffect(() => {
		if (props.open) {
			setOpenProxy(true);
		}
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		function handleEscKeypress(e) {
			if (e.keyCode === 27 && !preventClose) {
				setOpenProxy(false);
			}
		};

		document.addEventListener('keydown', handleEscKeypress);

		return () => document.removeEventListener('keydown', handleEscKeypress);
	}, [preventClose, setOpenProxy]);

	const className = [
		'Popup',
		...open ? ['open'] : [],
		...isOpening ? ['isOpening'] : [],
		...isOpened ? ['isOpened'] : [],
		...props.className ? [props.className] : [],
	].join(' ');

	useImperativeHandle(ref, () => ({
		openPopup: () => setOpenProxy(true),
		closePopup: () => setOpenProxy(false),
	}));

	return (
		<div
			className={className}
			onClick={(e) => !preventClose && e.currentTarget === e.target && setOpenProxy(!open)}
		>
			<div
				className={`popup-container ${props.containerClass || ''}`}
				_size={props.size}
			>
				<Flex
					justify="between"
					align="center"
					className={`back-btn ${props.backBtnClass || ''}`}
				>
					<span
						className="d-block d-lg-none text-info pointer mobile text-nowrap"
						onClick={() => !preventClose && setOpenProxy(false)}
					>
						<FontAwesomeIcon icon={faAngleLeft} className="f-rem-0.9" /> Back
					</span>

					{(loading || title) && (
						<Flex align="center" className="px-2.5 text-truncate">
							{loading && (
								<FontAwesomeIcon
									icon={faSpinnerThird}
									spin={true}
									className="text-secondary f-rem-0.9"
								/>
							)}
							{title && (
								<span
									className={classnames('text-truncate', {
										'ml-1.5': loading,
									})}
								>
									{title}
								</span>
							)}
						</Flex>
					)}
					<span
						className="d-none d-lg-block pointer desktop"
						onClick={() => !preventClose && setOpenProxy(false)}
					>
						<FontAwesomeIcon icon={faCircleXmark} className="f-rem-0.9" />
					</span>
				</Flex>

				<div className={props.childrenWrapperClass || ''}>
					{props.children || null}
				</div>
			</div>
		</div>
	);
}

export default forwardRef(Popup);
