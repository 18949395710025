import React from "react";
import Meta from "./../components/Meta";
import UsersTable from "./../components/UsersTable";
import { requireAuth } from "./../util/auth";

function UsersPage(props) {
  return (
    <>
      <Meta title="Users" />
      <UsersTable
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Users"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(UsersPage);
